<template>
  <v-btn
    block
    x-large
    :color="color"
    :outlined="!state"
    class="d-flex pa-4 text-center align-center justify-center text-button transition"
    @click="state = !state"
  >
    {{ text }}: {{ state ? "Sí" : "No" }}
  </v-btn>
</template>

<script>
export default {
  props: {
    value: Boolean,
    text: String,
    color: String,
  },
  computed: {
    state: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
        this.$emit("change", v);
      },
    },
  },
};
</script>

<style></style>
